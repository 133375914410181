export type ProductType = {
  id: string
  name: string
  description: string
  shopButtonUrl: string
  images: {
    square: string
    thumbnail: string
    mobile: string
    normal: string
    original: string
  }
  productUrl: string
  tag_based_key: string
  status: string
}

export type UploaderType = {
  id: string
  name: string
  username: string
  avatar: string
  social: {
    instagram_graph: {
      username: string
      source_id: string
    }
  }
}
export type MediaType = {
  id: string
  type: string
  source: {
    name: string
    id: string
    original: string
  }
  caption: string
  share: {
    url: string
    likes: number
  }
  date_published: string

  video_url: string | null
  share_url: string | null
  date_submitted: string
  favorite: boolean
  location: string | null
  sonar_place: string | null
  original_image_width: string
  original_image_height: string
  status: string
  likes: number
  request_id: string | null
  images: {
    square: string
    thumbnail: string
    mobile: string
    normal: string
    original: string
  }
  uploader: UploaderType
  products: ProductType[]
}

export type ErrorType = {
  errorCode: number
  message: string
}

export type A11yTextType = { [key: string]: string }

export type TrackingConfig = {
  eventName: string;
  properties: {
    [k: string]: any;
  };
  linkAttributes?: {
    [k: string]: string | number;
  }
}

export enum Locale {
  deDE = 'de-de',
  esES = 'es-es',
  fiFI = 'fi-fi',
  nlNL = 'nl-nl',
  itIT = 'it-it',
  svSE = 'sv-se',
  nbNO = 'nb-no',
  ptPT = 'pt-pt',
  daDK = 'da-dk',
  frCA = 'fr-ca',
  esUS = 'es-us',
  enCA = 'en-ca',
  nlBE = 'nl-be',
  enIE = 'en-ie',
  frBE = 'fr-be',
  frFR = 'fr-fr',
  frCH = 'fr-ch',
  deAT = 'de-at',
  deCH = 'de-ch',
  itCH = 'it-ch',
  enAU = 'en-au',
  enNZ = 'en-nz',
  enSG = 'en-sg',
  enIN = 'en-in',
}
