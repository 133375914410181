import daDK from './texts/da-dk.json'
import deDE from './texts/de-de.json'
import enIE from './texts/en-ie.json'
import esES from './texts/es-es.json'
import esUS from './texts/es-us.json'
import fiFI from './texts/fi-fi.json'
import frCA from './texts/fr-ca.json'
import frFR from './texts/fr-fr.json'
import itIT from './texts/it-it.json'
import nbNO from './texts/nb-no.json'
import nlNL from './texts/nl-nl.json'
import ptPT from './texts/pt-pt.json'
import svSE from './texts/sv-se.json'
import { Locale } from '../types'

type ResourcesType = Partial<Record<Locale, Record<string, string>>>
type FallbackResourcesType = Partial<Record<Locale | 'default', Array<Locale>>>

export const resources: ResourcesType = {
  [Locale.daDK]: daDK,
  [Locale.deDE]: deDE,
  [Locale.enIE]: enIE,
  [Locale.esES]: esES,
  [Locale.esUS]: esUS,
  [Locale.fiFI]: fiFI,
  [Locale.frCA]: frCA,
  [Locale.frFR]: frFR,
  [Locale.itIT]: itIT,
  [Locale.nbNO]: nbNO,
  [Locale.nlNL]: nlNL,
  [Locale.ptPT]: ptPT,
  [Locale.svSE]: svSE,
}

export const fallbackLng: FallbackResourcesType = {
  [Locale.frBE]: [Locale.frFR, Locale.enIE],
  [Locale.frCA]: [Locale.frFR, Locale.enIE],
  [Locale.nlBE]: [Locale.nlNL, Locale.enIE],
  [Locale.deAT]: [Locale.deDE, Locale.enIE],
  [Locale.deCH]: [Locale.deDE, Locale.enIE],
  [Locale.itCH]: [Locale.itIT, Locale.enIE],
  [Locale.frCH]: [Locale.frFR, Locale.enIE],
  [Locale.enAU]: [Locale.enIE],
  [Locale.enCA]: [Locale.enIE],
  [Locale.esUS]: [Locale.enIE],
  [Locale.enIN]: [Locale.enIE],
  [Locale.enNZ]: [Locale.enAU, Locale.enIE],
  [Locale.enSG]: [Locale.enAU, Locale.enIE],
  default: [Locale.enIE],
}

class I18n {
  locale: Locale = Locale.esUS
  constructor (locale = Locale.enIE) {
    if (locale && I18n.instances[locale]) {
      return I18n.instances[locale]
    }

    this.locale = locale in resources ? locale : (fallbackLng.default ? fallbackLng.default[0] : Locale.enIE)
    I18n.instances[locale] = this
  }

  static instances: Record<Locale, I18n> = {} as Record<Locale, I18n>

  setLocale (locale: Locale) {
    if (I18n.instances[locale]) {
      return I18n.instances[locale]
    }
    return new I18n(locale)
  }

  translate (key: string) {
    const translation = this.getTranslation(this.locale, key)
    if (translation !== null) {
      return translation
    }
    return this.getFallbackTranslation(key) || key
  }

  getTranslation (locale: Locale, key: string) {
    const translations = resources?.[locale]

    if (translations && typeof translations === 'object' && key in translations) {
      return translations[key]
    } else {
      return null
    }
  }

  getAllTexts () {
    if (resources[this.locale]) {
      return resources[this.locale]
    }
    const fallbacks = fallbackLng[this.locale] || fallbackLng.default
    if (fallbacks) {
      for (const fallback of fallbacks) {
        const translations = resources[fallback]
        if (translations !== null) {
          return translations
        }
      }
    }
  }

  getFallbackTranslation (key: string) {
    const fallbacks = fallbackLng[this.locale] || fallbackLng.default
    if (fallbacks) {
      for (const fallback of fallbacks) {
        const translation = this.getTranslation(fallback, key)
        if (translation !== null) {
          return translation
        }
      }
    }
    return null
  }
}

const i18nInstances: Record<Locale, I18n> = {} as Record<Locale, I18n>
export const getI18nInstance = (locale = Locale.enIE) => {
  locale = (locale as string).toLowerCase() as Locale

  if (!i18nInstances[locale]) {
    i18nInstances[locale] = new I18n(locale)
  }
  return i18nInstances[locale]
}
