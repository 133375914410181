import {
  BoundedContent,
  Box,
  Button,
  Column,
  FlexBox,
  GridContainer,
  Link,
  responsive,
  Row,
  Typography,
} from '@vp/swan'
import { MediaType, UploaderType } from '../../types'
import { useEffect, useState } from 'react'

interface IInfo {
  uploader: UploaderType
  data: MediaType
}
const mobileStyle = {
  position: 'relative',
  width: '100%',
}

const desktopStyle = {
  position: 'absolute',
  width: 'calc(100% - var(--swan-internal-grid-gutter) - var(--swan-internal-grid-gutter))',
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  borderBottomLeftRadius: 'var(--swan-sem-border-radius-container)',
  borderBottomRightRadius: 'var(--swan-sem-border-radius-container)',
}
const ResponsiveBox = responsive(Box)

export const Info: React.FC<IInfo> = ({ uploader, data }) => {
  const [captionExpanded, setCaptionExpanded] = useState(false)

  useEffect(() => {
    setCaptionExpanded(false)
  }, [data])

  const caption = data.caption
  const truncatedCaption = truncateByWords(data.caption, 9)
  return (
    <ResponsiveBox
      xs={{ style: mobileStyle, darkMode: false }}
      sm={{ style: desktopStyle, darkMode: true }}
      px='4'
      py='4'
    >
      <BoundedContent>
        <GridContainer>
          <Row>
            <Column span='10'>
              <Link
                href={data.source.original}
                skin='unstyled'
                target='_blank'
                rel='noreferrer'
              >
                <Typography fontSkin='body-small-bold'>
                  @{uploader.username}
                </Typography>
              </Link>
              {data.caption && (
                <>
                  <FlexBox>
                    <Typography
                      truncate={!captionExpanded}
                      fontSkin='footnote'
                      style={{ overflow: 'hidden' }}
                    >
                      {caption}
                    </Typography>
                  </FlexBox>
                  {caption !== truncatedCaption && (
                    <Button
                      width='full-width'
                      skin='unstyled'
                      onClick={() => setCaptionExpanded(!captionExpanded)}
                    >
                      <Typography fontSkin='body-small-bold' textAlign='right'>
                        {captionExpanded ? 'View Less' : 'View More'}
                      </Typography>
                    </Button>
                  )}
                </>
              )}
            </Column>
          </Row>
        </GridContainer>
      </BoundedContent>
    </ResponsiveBox>
  )
}

const truncateByWords = (str: string, limit = 6) =>
  str && str.split(' ').length > limit
    ? str.split(' ').slice(0, limit).join(' ') + '...'
    : str
