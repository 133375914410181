import { Card, BasicResponsiveImage } from '@vp/swan'

export const ImageContainer: React.FC<{ src: string }> = ({ src }) => {
  return (
    <Card fullBleed overflow='hidden' style={{ width: '100%', position: 'relative' }}>
      <BasicResponsiveImage
        aspectRatio={1}
        src={src}
        alt=''
      />
    </Card>
  )
}
