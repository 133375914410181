import { FlexBox, StandardTile, StandardTileImage, FluidImage, StandardTileContents, StandardTileName, responsive } from '@vp/swan'
import { ProductType, UploaderType } from '../../types'
import { trackEvent } from '../../utils/tracking'
const ResponsiveFlexBox = responsive(FlexBox)

export const ProductList: React.FC<{ products: ProductType[], uploader?: UploaderType }> = ({ products, uploader }) => {
  const productClick = (e: any, product: ProductType) => {
    trackEvent({
      eventName: 'Product Clicked',
      properties: {
        category: 'Social Native Feed',
        label: 'Social Native Feed Clicked',
        list_id: 'Product Carousel:Social Native Feed Carousel',
        eventDetail: uploader?.username,
        product_id: product.tag_based_key,                           // MPV ID of the product clicked
        name: product.name,                               // Product Name of the product clicked
        position: 1,
      }
    }, e, product.productUrl)
  }
  return (
    <ResponsiveFlexBox gap='4' xs={{ flexDirection: 'row' }} sm={{ flexDirection: 'column' }} alignItems='stretch'>
      {products.map((product) => {
        return (
          <StandardTile
            key={product.id}
            skin='product'
            imageWidth='full-bleed'
            onClick={productClick}
            render={(props: any) => <a className={props.className} onClick={(e) => productClick(e, product)} href={product.productUrl} style={{ flexShrink: 0, marginBottom: 0, width: '200px' }}>{props.children}</a>}
          >
            <StandardTileImage>
              {product.images.normal && <FluidImage src={product.images.normal} alt={product.name} />}
            </StandardTileImage>
            <StandardTileContents>
              <StandardTileName>{product.name}</StandardTileName>
            </StandardTileContents>
          </StandardTile>
        )
      })}

    </ResponsiveFlexBox>
  )
}
