import { getTracking } from '@vp/tracking'
import { TrackingConfig } from '../types'

export const trackEvent = (
  trackingConfig: TrackingConfig,
  event?: React.MouseEvent<HTMLElement, MouseEvent>,
  href?: string
) => {
  const { eventName, properties } = trackingConfig
  const tracking = getTracking()

  if (tracking && eventName) {
    tracking.track(eventName, properties)
  } else if (eventName) {
    const options = { once: true }
    // @ts-ignore
    window.addEventListener(
      'trackingReady',
      () => {
        const tracking = getTracking()
        tracking?.track(eventName, properties)
      },
      options
    )
  }

  if (event && href) {
    // Prevent the default behavior of the event
    event.preventDefault()
    // Prevent the event from bubbling up the DOM tree, preventing any parent handlers from being notified of the event
    event.stopPropagation()

    setTimeout(() => {
      // Navigate to the URL specified in the href attribute
      // @ts-ignore
      window.open(href)
    }, 1000)
  }
}
