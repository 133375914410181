/// <reference lib="dom" />
import { BasicResponsiveImage, Card, Carousel, CarouselSlide, Callout, FlexBox, ScreenClassProvider, BoundedContent, responsive, Box, Column, GridContainer, H2, Row, Typography } from '@vp/swan'
import { useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { Modal } from './modal/Index'
import { A11yTextType, Locale, MediaType } from '../types'
import { createContext, useEffect, useRef, useState } from 'react'
import { trackEvent } from '../utils/tracking'
import { I18nProvider } from '../localization/LanguageContext'

export interface Props {
  media?: Array<MediaType>
  a11yText?: A11yTextType,
  texts?: Record<string, string>
}
const ResponsiveCarousel = responsive(Carousel)
export const A11yTextContext = createContext<A11yTextType>({})

export const Fragment: React.FC<Props> = ({ media, a11yText, texts }) => {
  useSwanStyleKeys(['core', 'carousel', 'grid', 'modalDialog', 'button', 'standardTile', 'spinner'])
  const { locale } = useUserContext()
  const [activeIndex, setActiveIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [inViewport, setInViewport] = useState(false)
  const divRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInViewport(true)
        }
      },
      { threshold: 0.5 } // Trigger when at least 50% of the div is visible
    )

    if (divRef.current) {
      observer.observe(divRef.current)
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current)
      }
    }
  }, [])

  useEffect(() => {
    // loaded only once
    if (inViewport) {
      trackEvent({
        eventName: 'Carousel Viewed',
        properties: {
          category: 'Social Native Feed',
          label: 'Carousel Viewed'
        }
      })
    }
  }, [inViewport])
  if (!media) {
    return <></>
  }
  return (
    <I18nProvider defaultLocale={locale as Locale}>
      <ScreenClassProvider>
        <BoundedContent ref={divRef} id='socialCurations' mb='between-sections'>
          <Box>
            <GridContainer mb='6'>
              {texts?.heading &&
                <Row>
                  <Column span={12}>
                    <H2 fontSkin='title-section' mb='2'>
                      {texts.heading}
                    </H2>
                  </Column>
                </Row>}
              {texts?.subheading &&
                <Row>
                  <Column span={12}>
                    <Typography component='p' fontSkin='body-standard' mt='0' mb='0'>
                      {texts.subheading}
                    </Typography>
                  </Column>
                </Row>}
            </GridContainer>
          </Box>
          <Box>
            <GridContainer>
              <Row key={1}>
                <Column span={12}>

                  <ResponsiveCarousel
                    gridGutters
                    infinite={false}
                    slidesToShow={{ sm: 4.2, md: 4.2, xs: 1, lg: 6.2 }}
                    accessibleTextForPrevious={a11yText?.['prevSlide']}
                    accessibleTextForNext={a11yText?.['nextSlide']}
                    xs={{ slidesToScroll: 1 }}
                    lg={{ slidesToScroll: 6 }}
                    md={{ slidesToScroll: 4 }}
                    sm={{ slidesToScroll: 4 }}
                    beforeChange={(currentSlide: number, nextSlide: number) => {
                      let direction = 'Right Arrow'
                      if (currentSlide > nextSlide) {
                        direction = 'Left Arrow'
                      }
                      trackEvent({
                        eventName: 'Carousel Clicked',
                        properties: {
                          category: 'Social Native Feed',
                          label: direction
                        }
                      })
                    }}
                  >
                    {media?.map((item, index) => (
                      <CarouselSlide key={index}>
                        <Card
                          fullBleed overflow='hidden' style={{ position: 'relative' }} onClick={() => {
                            setActiveIndex(index); setIsModalOpen(true)
                            trackEvent({
                              eventName: 'Carousel Clicked',
                              properties: {
                                category: 'Social Native Feed',
                                label: `${item.id}:${index}_${media.length}`
                              }
                            })
                          }}
                        >
                          <BasicResponsiveImage
                            aspectRatio={1}
                            src={`${item.images.normal}`}
                          />
                          <FlexBox justifyContent='center' style={{ position: 'absolute', flex: 1, width: '100%', bottom: '5%' }}>
                            <Callout border='none' size='mini'>{`@${item.uploader.username}`}</Callout>
                          </FlexBox>
                        </Card>
                      </CarouselSlide>
                    ))}
                  </ResponsiveCarousel>
                </Column>
              </Row>
            </GridContainer>
          </Box>
          {media && <Modal activeIndex={activeIndex} media={media} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />}

        </BoundedContent>
      </ScreenClassProvider>
    </I18nProvider>
  )
}
