import { Carousel, CarouselSlide, Column, GridContainer, ModalDialog, ModalDialogBody, ModalDialogCloseButton, ModalDialogContent, ModalDialogNav, responsive, Row } from '@vp/swan'
import React, { useEffect, useRef } from 'react'
import { ProductList } from './ProductList'
import { ImageContainer } from './ImageContainer'
import { MediaType } from '../../types'
import { Info } from './Info'
import { trackEvent } from '../../utils/tracking'
import { useI18n } from '../../localization/LanguageContext'

interface IModal {
  isOpen: boolean,
  activeIndex: number,
  media: MediaType[]
  setIsOpen: (arg0: boolean) => void
}
const ResponsiveModalDialogContent = responsive(ModalDialogContent)
const ResponsiveCarousel = responsive(Carousel)

export const Modal: React.FC<IModal> = ({ isOpen, activeIndex, media, setIsOpen }) => {
  const { translate } = useI18n()

  useEffect(() => {
    if (carousel.current) {
      // @ts-ignore
      carousel.current.slickGoTo(activeIndex, true)
    }
    if (isOpen) {
      afterChange(activeIndex)
    }
  }, [activeIndex, isOpen])
  const carousel = useRef(null)

  const afterChange = (index: number) => {
    const { products, uploader } = media[index] ?? {}

    trackEvent({
      eventName: 'Product List Viewed',
      properties: {
        label: 'Social Native Feed Carousel',
        list_id: 'Social Native Feed Carousel',
        products: products.map((product, index) => {
          return {
            product_id: product.tag_based_key,
            name: product.name,
            position: index,
            list_section_id: uploader?.username,
          }
        })

      }
    })
  }
  return (
    <ModalDialog isOpen={isOpen} bodyWidth='grow' onRequestDismiss={() => setIsOpen(false)}>
      <ResponsiveModalDialogContent
        fullBleed aria-labelledby={translate('Modal Title')}
        sm={{ style: { width: '875px' } }}

      >
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={translate('close')} />
        </ModalDialogNav>

        <ModalDialogBody px='8'>
          <ResponsiveCarousel
            ref={carousel}
            accessibleTextForPrevious={translate('prevSlide')}
            accessibleTextForNext={translate('nextSlide')}
            initialSlide={activeIndex}
            slidesToShow={1}
            xs={{ arrowVerticalOffset: '-100px' }}
            sm={{ arrowVerticalOffset: '0' }}
            afterChange={afterChange}
          >
            {media.map((item) => {
              return (
                <CarouselSlide key={item.id}>
                  <GridContainer>
                    <Row>
                      <Column span='8' mb='6'>
                        <ImageContainer src={item?.images.original} />
                        <Info uploader={item?.uploader} data={item} />
                      </Column>
                      <Column span='4' mb='6' overflow='auto' style={{ maxHeight: '520px' }}>
                        {Array.isArray(item.products) && <ProductList products={item.products} uploader={item.uploader} />}

                      </Column>
                    </Row>
                  </GridContainer>
                </CarouselSlide>
              )
            })}

          </ResponsiveCarousel>

        </ModalDialogBody>
      </ResponsiveModalDialogContent>
    </ModalDialog>
  )
}
